<template>
	<div>
		<!-- 航班信息 -->
		<!-- 去程 -->
		<el-card class="flightInfo">
			<div slot="header" class="flight-title  flex justify-content-space-between">
				<span class="city-name">
					<span>{{tkQuery.travelType == 1?"单程":"往返"}}: </span>
					<span>{{tkQuery.dep}}</span>
					
					<span class="iconfont yj-icon-tp_icon_dcbz1" v-show="tkQuery.travelType == 1"></span>
					<span class="iconfont yj-icon-iconfonteg" v-show="tkQuery.travelType == 2"></span>
					
					<span>{{tkQuery.arr}}</span>
				</span>
				
				<span class="change-flight-icon pointer " @click="changeFlight">更换航班</span>
			</div>
			
			<div class="flightRoute " v-if="chooseFlight && chooseFlight.flightNo">
				<div class="flightRoute-airport   flex justify-content-space-between">
					<span class="">去程：{{tkQuery.dep}}-{{tkQuery.arr}} | {{chooseFlight.departDate}}</span>
					<span class=" btn-tgqsm" @click="lookDetail(chooseFlight.chooseCabin)">
						退改签说明
					
					</span>
						
				</div>
				<div class="flightRoute-detail flex justify-content-space-between" >
					<div class="time-info   flex justify-content-space-between">
						<div class="">
							<div class="one-line">{{chooseFlight.departTime}}</div>
							<div class="one-line">&nbsp;</div>
							<div class="one-line">{{chooseFlight.arriveTime}} 
								<span class="day">{{calcDay(chooseFlight)}}</span>
							</div>
						</div>
						<div class=" line-middle">
							<div class="line-circle"></div>
							<div class="c-line"></div>
							<div class="line-circle"></div>
						</div>
						<div class="">
							<div class="one-line">{{chooseFlight.departAirportName}}{{chooseFlight.departTerminal}}</div>
							<div class="one-line">{{chooseFlight.flyTimeStr}}</div>
							<div class="one-line">{{chooseFlight.arriveAirportName}}{{chooseFlight.arriveTerminal}}</div>
						</div>
					</div>
					<div class="air-info right text-right">
						<div class="air-info-item">
							<span v-show="chooseFlight.sharFlightNo">共享:</span>
							<img class="air-img-icon" :src="chooseFlight.airlineImg" alt="">
							<span>{{chooseFlight.airlineName}}</span>
						</div>
						<div class="air-info-item">{{chooseFlight.flightNo}} {{chooseFlight.planeName || chooseFlight.plane}}</div>
					
						<div  v-show="chooseFlight.sharFlightNo">
							<div  class="air-info-item">
								<span>实际承运：</span>
								<span>{{chooseFlight.sharFlightNo}}</span>
							</div>
							<div class="air-info-item">
								<img class="air-img-icon" :src="chooseFlight.shareAirlineImg" alt="">
								<span>{{chooseFlight.shareAirlineName}}</span>
							</div>
						</div>
						<div class="air-info-item">{{chooseFlight.chooseCabin.cabinName}}/{{ chooseFlight.chooseCabin.cabin}}</div>
					</div>
				</div>
			</div>
			<!-- 返程 -->
			<div class="flightRoute " style="margin-top: 30px;" v-if="chooseFlightBack && chooseFlightBack.flightNo">
				<div class="flightRoute-airport   flex justify-content-space-between">
					<span class="">返程：{{tkQuery.arr}}-{{tkQuery.dep}} | {{chooseFlightBack.departDate}}</span>
					<span class=" btn-tgqsm" @click="lookDetail(chooseFlightBack.chooseCabin)">退改签说明</span>
						
				</div>
				<div class="flightRoute-detail flex justify-content-space-between" >
					<div class="time-info   flex justify-content-space-between">
						<div class="">
							<div class="one-line">{{chooseFlightBack.departTime}}</div>
							<div class="one-line">&nbsp;</div>
							<div class="one-line">{{chooseFlightBack.arriveTime}}
							 <span class="day">{{calcDay(chooseFlight)}}</span>
							</div>
						</div>
						<div class=" line-middle">
							<div class="line-circle"></div>
							<div class="c-line"></div>
							<div class="line-circle"></div>
						</div>
						<div class="">
							<div class="one-line">{{chooseFlightBack.departAirportName}}{{chooseFlightBack.departTerminal}}</div>
							<div class="one-line">{{chooseFlightBack.flyTimeStr}}</div>
							<div class="one-line">{{chooseFlightBack.arriveAirportName}}{{chooseFlightBack.arriveTerminal}}</div>
						</div>
					</div>
					<div class="air-info right text-right">
						<div class="air-info-item">
							<span v-show="chooseFlightBack.sharFlightNo">共享:</span>
							<img class="air-img-icon" :src="chooseFlightBack.airlineImg" alt="">
							<span>{{chooseFlightBack.airlineName}}</span>
						</div>
						<div class="air-info-item">{{chooseFlightBack.flightNo}} {{chooseFlightBack.planeName || chooseFlightBack.plane}}</div>
						<div  v-show="chooseFlightBack.sharFlightNo">
							<div  class="air-info-item">
								<span>实际承运：</span>
								<span>{{chooseFlightBack.sharFlightNo}}</span>
							</div>
							<div class="air-info-item">
								<img class="air-img-icon" :src="chooseFlightBack.shareAirlineImg" alt="">
								<span>{{chooseFlightBack.shareAirlineName}}</span>
							</div>
						</div>
						<div class="air-info-item">{{chooseFlightBack.chooseCabin.cabinName}}/{{ chooseFlightBack.chooseCabin.cabin}}</div>
					</div>
				</div>
			</div>
		</el-card>
		
		<el-dialog
		  title="退改签"
		  :visible.sync="dialogVisible"
		  :modal="true"
		  append-to-body
		  custom-class="exit-alter-info">
				<product-rule
					style="position: absolute;top: 0;left: 0;z-index: 3010;"
					:priceItem="priceItem"
				></product-rule>
		  <span slot="footer" class="dialog-footer">
		   
		  </span>
		</el-dialog>
	
		
	</div>
</template>

<script>
	import ProductRule from './../tkQuery/ProductRule.vue'
	export default {
		name:'TkEditFlightInfo',
		data(){
			return {
				dialogVisible:false,
				priceItem:{},
			}
		},
		components:{
			ProductRule
		},
		props:{
			tkQuery:{
				type:Object,
				default(){
					return {}
				}
			},
			// 去程
			chooseFlight:{
				type:Object,
				default(){
					return {}
				}
			},
			// 返程
			chooseFlightBack:{
				type:Object,
				default(){
					return {}
				}
			},
		},
		computed:{
			travelType(){
				return this.tkQuery.travelType || 1
			}
		},
		methods:{
			// 查看详情
			lookDetail(priceItem){
				this.dialogVisible = true;
				this.priceItem = priceItem;
			},
			// 计算天数
			calcDay(flightItem){
				var dayStr = '';
				let day = this.$common.getDiffDay(flightItem.departDate,flightItem.arriveDate);
				if(day>0){
					dayStr = `+${day}`
				}
				return dayStr
			},
			// 更换航班(flightItem) 或者重新查询
			changeFlight(){
				this.$emit('changeFlight',true)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	/deep/ .el-card__header{
		padding: 0 20px;
		background:#006BB9 ;
	}
	
	 .exit-alter-info{
		padding: 0;
		max-width: 1000px !important;
	}
	
	/* .clear{
		clear: both;
	}
	.left{
		float: left;
	}
	.right{
		float:right;
	} */
	.text-left{
		text-align: left;
	}
	.text-right{
		text-align: right;
	}
	
	.flightInfo{
		
		background: #fff;
		position: relative;
	}
	.flight-title{
		height:60px;
		border-top-left-radius:5px;
		border-top-right-radius:5px;
		color:#fff;
		line-height: 60px;
		margin:0 -20px;
		padding: 0 20px;
		
		.city-name{
			font-size:18px;
			font-weight: 600;
		}
		.change-flight-icon{
			font-size:12px;
		}
	}
	
	
	.flightRoute-airport{
		font-size:14px;
		font-family: '微软雅黑';
		font-weight:600;
		color:rgba(34,51,68,1);
		
		width:100%;
	}
	.flightRoute-detail{
		width:100%; 
	}
	.btn-tgqsm{
		font-size:12px;
		font-family: '微软雅黑';
		font-weight:400;
		color:rgba(0,122,255,1);
		// position:relative;
		.exit-alter-rule-alert{
			display: none;
			color: #424242;
		}
	}
	.btn-tgqsm:hover{
		.exit-alter-rule-alert{
			display: block;
		}
	}
	.one-line{
		height:30px;
		line-height: 30px;
		text-align: left;
	}
	.c-line{
		height:50px;
		width:2px;
		background: #C4CDDD;
		position: relative;
		left:3px;
	}
	.line-circle{
		width: 9px;
		height:9px;
		border-radius: 50%;
		border: 1px solid #C4CDDD;
		background: #fff;
	}
	.line-middle{
		padding: 10px 7px 0 7px;
	}
	.routeLine{
		width: 360px;
		margin:0 auto;
		border-bottom:1px dashed #C4CDDD;
	}
	.air-info{
		padding-top:10px;
		font-size:12px;
		font-family: '微软雅黑';
		font-weight:400;
		color:rgba(116,129,151,1);
	}
	.air-info .air-info-item{
		height: 20px;
		line-height: 20px;
	}
	.pricedetail{
		background: #fff;
		margin-top: 14px;
		padding: 20px;
	}
	.price-line{
		line-height: 25px;
		height: 25px;
	}
	.pricedetail .routeLine{
		padding: 10px 0;
	}
	.total-price{
		text-align: right;
		padding-top: 16px;
		padding-bottom: 10px;
		font-family: '微软雅黑';
		font-weight:bold;
		color:rgba(34,51,68,1);
	}
	.total-price .ch{
		font-size:12px;
	}
	.total-price .num{
		font-size:24px;
	}
	
	.air-img-icon{
		width: 12px;
		height: 12px;
	}
	
</style>
