<template>
	<div class="tk-book-rule">
		<!--机票预订须知-->
		<div class="book-rule-content" v-if="tnType==1">

			<div class="oldHtmlpage">
				<p>
					<strong>1. 定义</strong>
				</p>
				<p>
					在本规则与条款中，除非另有定义，则“南航明珠俱乐部”指由中国南方航空股份有限公司（以下简称“南航”）管理和运营的常旅客奖励计划。
				</p>
				<p>
					“奖励里程”包括基本飞行里程、消费里程、促销里程、精英里程。“奖励里程”可以用于兑换奖励机票和奖励升舱以及其它“南航明珠俱乐部”指定的奖励品。
				</p>
				<p>
					“标准里程”是指国际航空运输协会IATA公布的城市对距离。当城市对距离小于500公里时，标准里程按500公里计算。
				</p>
				<p>
					“基本飞行里程”是指通过乘坐南航及航空合作伙伴的有效航班及舱位所累积的里程。由“标准里程×舱位累积比例”计算所得。
				</p>
				<p>
					“消费里程”是指南航会员通过使用非航空合作伙伴的有效产品或服务所累积的消费里程。
				</p>
				<p>
					“促销里程”是指南航会员通过参加“南航明珠俱乐部”公布的促销活动，获得该项活动中的促销里程。促销里程的获得方式和使用限制由每次活动的活动规则决定。
				</p>
				<p>
					“精英里程”专为南航铂金、金、银卡会员所享有。根据铂金、金、银卡级别和航线的标准里程数所获取的额外奖励里程。“精英里程”仅记入“奖励里程”，不记入“升级里程”。
				</p>
				<p>
					“升级里程”是指南航会员通过乘坐南航及指定航空合作伙伴的有效航班及舱位所累积的基本飞行里程，不包括促销里程、非航空合作伙伴消费里程以及精英里程。升级里程用于获取精英会员资格。
				</p>
				<p>
					“升级航段”是指南航会员通过乘坐南航及指定航空合作伙伴的有效航班及舱位时所累积的基本飞行航段。升级航段用于获取精英会员资格。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>2. 会员资格和里程账号管理</strong>
				</p>
				<p>
					2.1
					凡年满2周岁并同意接受“南航明珠俱乐部”规则和条款的自然人，均可免费申请成为“南航明珠俱乐部”会员，除非申请人所在国的法律明文禁止。2-11周岁的小明珠会员需填写真实有效的监护人信息，方允许注册“南航明珠俱乐部”会员。
				</p>
				<p>
					2.2 “南航明珠俱乐部”不接受法人或其它非法人组织的申请，也不受理数人联合申请或同一人多次申请。
				</p>
				<p>
					2.3 申请人必须按要求完整填写并寄交。申请表必须由本人签名，以示接受“南航明珠俱乐部”的规定和条款。
				</p>
				<p>
					2.4 会员卡获取
				</p>
				<p>
					2.4.1 所有南航会员入会后均可以通过南航官网、“中国南方航空”或“南方航空”微信公众号以及南航手机客户端下载电子会员卡。
				</p>
				<p>
					2.5 会员卡仅供会员个人使用，不得转让。
				</p>
				<p>
					2.6
					会员必须依据“南航明珠俱乐部”的规定行使会员权利，若会员误用或滥用会员权利、假报有关信息时，“南航明珠俱乐部”可于事先通知或不通知的情况下，终止会员资格并收回会员卡，同时作废已累积的全部里程积分，南航将不负责任何赔偿。
				</p>
				<p>
					2.7 里程帐户（卡号）管理
				</p>
				<p>
					2.7.1 申请会员资格获准后，每个会员将拥有且仅可拥有一个里程帐户（即会员卡号）。南航不接受重复申请并保留对重复帐户进行清理或删除的权利。
				</p>
				<p>
					2.7.2 如果一个会员同时拥有多个里程账户，会员可以指定最终使用的卡号，其它卡号信息将合并到指定卡号中。
				</p>
				<p>
					2.7.3 转出卡和转入卡都必须通过身份认证后，方可申请卡号合并。
				</p>
				<p>
					2.7.4 卡号合并后，受让人名单将以转入卡登记的为准。如转出卡曾添加过受让人（以系统查核为准），原登记的受让人将于添加之日起30天后生效。
				</p>
				<p>
					2.7.5 会员申请卡号合并后，转出里程的卡号将不能再使用，“南航明珠俱乐部”有权从系统中受让人删除转出卡号。
				</p>
				<p>
					2.7.6
					卡号合并后将基于转入卡的会员级别和账户情况重新计算转出卡的里程，包括精英奖励里程和促销奖励里程，例如，明珠卡转入金卡后将按金卡标准重新计算，对符合条件的航段补赠金卡奖励里程，反之如果金卡转入至明珠卡，原金卡精英级别里程将被删除。
				</p>
				<p>
					2.8
					会员必须到南航指定售票处或登录官网在线办理身份认证后，方可进行卡号合并以及在会员网站为他人兑换奖励机票、兑换非航空奖励品，管理受让人名单、定制通讯服务、添加或修改证件号码等。小明珠会员不允许添加受让人名单及为他人兑换奖励机票。
				</p>
				<p>
					2.9 办理身份认证
				</p>
				<p>
					2.9.1 在线身份认证：会员可登录官网会员账户后通过输入银行卡信息进行校验，完成身份认证。
				</p>
				<p>
					2.9.2 到指定售票处办理身份认证：
				</p>
				<p>
					2.9.2.1 会员本人办理身份认证时，须提供：会员本人有效身份证件原件、复印件、会员卡或会员卡号，以及提供会员本人中国境内手机号码(移动、联通或电信手机号均可)或电子邮箱作为接收密码的安全联系信息。
				</p>
				<p>
					2.9.2.2 委托他人办理时，除提供以上证件，还须提供代办人的身份证原件、复印件。
				</p>
				<p>
					2.9.2.3 成人旅客购票、乘机，以及南航会员身份认证等所有需要出示身份证才能办理的业务，都只认可二代身份证，其他允许作为购票及乘机使用的有效身份证件仍按原规定执行。
				</p>
				<p>
					2.9.2.4 一人一天最多可为三人代办身份认证业务。如办理身份认证时所出示的会员证件非《中华人民共和国第二代居民身份证》，不允许他人代办身份认证业务。
				</p>
				<p>
					2.9.2.5 已消磁未能通过识别器验证的二代身份证，不允许办理身份认证。
				</p>
				<p>
					2.9.2.6 会员账户如没有任何乘机记录，必须由会员本人亲自办理，不允许委托他人代办。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>3. 里程累积</strong>
				</p>
				<p>
					<strong>3.1 累积总则</strong>
				</p>
				<p>
					3.1.1 “南航明珠俱乐部”以“里程”作为累积单位，里程累积的基本计算单位为公里。
				</p>
				<p>
					3.1.2 入会前且在补登期内的有效乘机可以通过里程补登累积。
				</p>
				<p>
					3.1.3 里程帐户仅供个人进行里程累积。
				</p>
				<p>
					3.1.4 团队机票、里程兑换的奖励机票、旅游同业或其他特殊折扣机票和免费机票、专机和包机航班机票不可累积里程。
				</p>
				<p>
					3.1.5 里程兑换的奖励升舱机票、促销获赠的免费升舱机票、非自愿升舱机票按未升舱前所购舱位累积里程。
				</p>
				<p>
					3.1.6 无论是自愿或非自愿转乘其它航空公司的航班，如果您最终乘坐的航班不在南航会员可累积的范围之内，该次航班则不可进行里程累积。
				</p>
				<p>
					3.1.7 会员的累积里程将登录其本人的里程帐户，不同个人的里程帐户不得合并使用。
				</p>
				<p>
					3.1.8 为保证会员获得的里程能准确及时地登录到其帐户中，会员应按会员手册中里程累积办法执行。
				</p>
				<p>
					3.1.9 请保留您所有的里程登记资料（包括机票/电子客票行程单复印件和登机牌原件、合作伙伴消费帐单等），以便您进行里程的核对查实。
				</p>
				<p>
					3.1.10
					为了确保里程准确累积，请在订座购票、机场办理乘机手续或使用合作伙伴产品或服务时出示您的会员卡或提供会员卡号，并确保旅客姓名、身份证件信息与您南航会员账户中登记的一致，保留好机票和登机牌，直至确认该次乘机的里程已准确累积到您的里程账户。
				</p>
				<p>
					3.1.11 如乘坐代码共享航班，将按照客票上显示的承运人、航班号、座位等级/舱位等实际成行的航班信息累积里程。
				</p>
				<p>
					3.1.12 当搭乘南航或航空合作伙伴的航班时，对于同一个航段会员只可选定一家航空公司的常旅客计划累积里程。每次符合累积标准的飞行记录仅可记入一个航空公司的一个会员帐户，不可在多个帐户重复累积。
				</p>
				<p>
					<strong>3.2 里程有效期及延期</strong>
				</p>
				<p>
					3.2.1
					12周岁（含）以上南航会员里程有效期为36个月（如有特指除外），乘机里程自航班日期起开始计算有效期，非乘机里程自入账日期起开始计算有效期。2-11周岁的小明珠会员里程不设有效期，但年满12周岁之日起，小明珠会员将直接转为南航明珠卡会员，里程有效期按照明珠卡会员里程有效期36个月计算。
				</p>
				<p>
					3.2.2
					铂金、金、银卡会员在其级别期内不会发生里程失效，如降为明珠卡仍会有12个月的里程保护期。在精英级别有效期和里程保护期内不对会员账户执行里程失效，例如，会员在2018年3月降为明珠卡，则在精英级别有效期和里程保护期内的到期里程统一保护至2019年3月31日（24时）执行失效或明珠卡延期政策。
				</p>
				<p>
					3.2.3 明珠卡会员在里程到期前成功注册“南航会员里程延期计划”，80%的到期里程将会自动延期12个月，每笔到期里程仅执行一次延期。
				</p>
				<p>
					<strong>3.3 里程补登</strong>
				</p>
				<p>
					3.3.1 乘机飞行里程的补登：飞行里程补登的有效期为旅行之日起六个月内的航班。超过补登期限，将不予受理。
				</p>
				<p>
					3.3.1.1 南航航班里程补登：致电南航销售服务热线（95539），提供具体的乘机信息进行里程补登，或提供登机牌复印件及机票（或电子客票行程单）复印件作为补登凭据。
				</p>
				<p>
					3.3.1.2 四川航空航班的里程补登规则，同南航航班补登规则3.3.1.1，其他航空合作伙伴航班的里程补登，请邮寄外航的登机牌原件及机票（或电子客票行程单）复印件至明珠俱乐部，两件缺一不可。
				</p>
				<p>
					3.3.2 提交补登材料上需注明会员卡号，资料不全或内容不清晰的里程补登材料将不予受理。
				</p>
				<p>
					<strong>3.4 自动识别服务</strong>
				</p>
				<p>
					3.4.1
					为了方便南航会员累积里程，“南航明珠俱乐部”为所有会员初始开通了会员卡号自动识别服务，即南航会员搭乘南航航班时，如果旅客的姓名和有效身份证件号码与其在南航会员账户登记的信息一致，旅客的南航会员卡号将会被自动识别，所乘坐的南航段里程可能被自动累积进账。自动识别服务仅作为会员累积里程的一个补充途径，并不能完全保证里程的自动累积入账，会员仍需要在购票或办理值机手续时出示南航会员卡和及时确认账户的里程累积情况。
				</p>
				<p>
					3.4.2 如果希望将所乘坐的南航段乘机记录累积到“南航明珠俱乐部”以外的其他航空公司常旅客计划，请在办理值机手续时出示该公司的会员卡号。
				</p>
				<p>
					3.4.3 如果不需要自动识别服务，会员可以登陆南航官网的南航会员账户，在南航会员服务下的服务订制页面取消自动识别服务，已累积进账的里程将不予删除。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>4. 里程兑换</strong>
				</p>
				<p>
					<strong>4.1 兑换总则</strong>
				</p>
				<p>
					4.1.1 “南航明珠俱乐部”不设里程起兑门槛，会员达到兑换标准即可进行兑换，如兑换产品有特殊说明除外。
				</p>
				<p>
					4.1.2 航班上允许使用里程兑换的奖励机票或奖励升舱的座位数量有限，在春运与暑期旺季期间，座位供给可能会减少。
				</p>
				<p>
					4.1.3 奖励机票自旅行之日起一年内有效，完全未使用的奖励机票自填开之日起一年内有效。
				</p>
				<p>
					4.1.4 婴儿和儿童的兑换标准与成人的兑换标准相同。
				</p>
				<p>
					4.1.5
					奖励机票或奖励升舱的乘机人姓名和有效身份证件号码，默认为会员账户中登记的会员本人或受让人信息；若有误或显示为空，请致电95539更正账户信息后再兑换；奖励机票出票后，旅客姓名以及证件号码不可变更；若出票后发现信息有误，请申请自愿退票后再重新兑换。在非变更乘机人的前提下，乘机人姓名填写有误，申请改名参见最新版的《办理南航电子客票改名规定》。
				</p>
				<p>
					4.1.6 奖励机票和奖励升舱如涉及无人陪伴儿童等特殊服务，会员须遵照航空公司规定流程申请办理，服务费由旅客自行负担（但奖励机票/升舱暂不适用于担架旅客）。
				</p>
				<p>
					4.1.7 会员在南航指定售票处开具奖励机票或办理奖励升舱时，应提供取票人或办理人的身份证原件和复印件。
				</p>
				<p>
					4.1.8 会员要求退票，应在开始旅行之日起（客票第一航段未使用的，从填开之日起）十三个月内提出，否则南航有权拒绝办理。
				</p>
				<p>
					4.1.9 申请奖励机票退票时，乘机人必须出示未使用的电子客票行程单（或纸质客票的乘机联和旅客联）及本人的身份证原件（若为他人代办，还应提供代办人的身份证原件），并提供会员卡号，方可办理退票手续。
				</p>
				<p>
					4.1.10 奖励机票遗失不补，里程不退还。
				</p>
				<p>
					4.1.11 非自愿情况下的变更和退票规定
				</p>
				<p>
					4.1.11.1 非自愿变更和退票的适用情况请参见《南航旅客、行李国内/国际运输总条件》相关内容。
				</p>
				<p>
					4.1.11.2 非自愿变更的里程退还原则
				</p>
				<p>
					➢造成旅客舱位等级变更时，南航里程兑换机票或升舱的里程差额多退少不补。
				</p>
				<p>
					➢如造成非自愿降舱，按舱位和折扣率计算降舱差价，全部退回会员账户；如为非自愿升舱，不再补收里程差价。
				</p>
				<p>
					4.1.11.3 非自愿退票的里程退还原则
				</p>
				<p>
					➢如客票完全未使用，则退还全部原实付里程；如客票已部分使用，（1）南航国内客票：退还未使用航段相同舱位和折扣率的兑换里程，但不得超过原实付里程，不收取任何退票费；（2）南航国际（/地区）客票：原实付里程扣除已使用航段的相同舱位和折扣率的兑换里程，退还剩余部分里程，不收取任何退票费。
				</p>
				<p>
					➢班机如在非经停点的其他航站降落，取消当日飞行，旅客要求退票，应退还由降落站至到达站与原实付里程相同舱位和折扣率的兑换里程，但不得超过原实付里程，不收取退票费。如无法在查询到降落站至到达站的兑换标准，则视降落站至到达站航距退还里程：降落站至到达站距离，如小于原始发站至到达站距离的1/2，则退回50%原实付里程；如大于，则退回全部原实付里程。
				</p>
				<p>
					4.1.11.4 非自愿变更和非自愿退票情况下退还的里程，将在退还日期的基础上重新赋予36个月有效期。
				</p>
				<p>
					4.1.11.5 如需办理非自愿变更和非自愿退票情况下里程退还，请持相关航班证明至南航直属售票处或致电南航销售服务热线95539申请办理。
				</p>
				<p>
					4.1.12 其它未列明的运输条件同正常付费机票。
				</p>
				<p>
					<strong>4.2 南航奖励机票标准兑换</strong>
				</p>
				<p>
					4.2.1. 标准兑换座位数量有限，兑换标准详见《南航奖励机票兑换标准表》。
				</p>
				<p>
					4.2.3. 标准兑换适用于单程、往返程和缺口程的航段兑换，不允许中途分程。
				</p>
				<p>
					4.2.4. 标准兑换必须确定航程、航班和日期，不接受不定期航班兑换。
				</p>
				<p>
					4.2.5 标准兑换允许在有效期内基于同等兑换舱位变更航班或日期，如选择降舱变更，差额里程不退还，变更费标准如下：
				</p>
				<p>
					4.2.5.1. 南航国内航线：
				</p>
				<p>
					● 航班预计离站时间之前24小时（含）前变更，每次每张收取50元的变更费；
				</p>
				<p>
					● 航班预计离站时间之前24小时（不含）后变更，每次每张收取100元的变更费。
				</p>
				<p>
					4.2.5.2. 南航国际航线及中国港澳台地区航线：
				</p>
				<p>
					● 航班预计离站时间之前24小时（含）前变更，每次每张收取100元的变更费；
				</p>
				<p>
					● 航班预计离站时间之前24小时（不含）后变更，每次每张收取200元的变更费。
				</p>
				<p>
					4.2.6. 标准兑换允许在有效期内退票，收取50%未使用航段里程作为退票手续费，退还里程与兑换时有效期相同，仅退还有效期内的里程；非自愿退票全部退还未使用航段里程，退还里程自退还之日起36个月有效。
				</p>
				<p>
					4.2.7. 标准兑换如果仅支付里程但未完成燃油税费和出票，则不允许变更和签转。
				</p>
				<p>
					<strong>4.3 南航奖励机票动态兑换</strong>
				</p>
				<p>
					4.3.1. 动态兑换支持在南航手机客户端预订和兑换，变更和退票请致电南航客服热线95539。
				</p>
				<p>
					4.3.2. 动态兑换座位数量比标准兑换更多，兑换标准以南航手机客户端实时查询为准。 4.3.3. 动态兑换适用于南航实际承运的南航国内航线航班，不含南航代码共享航班。
				</p>
				<p>
					4.3.4. 动态兑换适用于单程、往返程的航段兑换，不允许中途分程。
				</p>
				<p>
					4.3.5. 动态兑换必须确定航程、航班和日期，不接受不定期航班兑换。
				</p>
				<p>
					4.3.6. 动态兑换允许在有效期内变更航班日期和退票，不允许签转，具体以客票条件为准。
				</p>
				<p>
					4.3.7. 动态兑换变更费以里程收取，支持原兑换账户里程支付，如里程不足变更可申请退票。
				</p>
				<p>
					4.3.8. 动态兑换退票发生的退还里程自退还之日起36 个月有效。
				</p>
				<p>
					<strong>4.4 南航奖励升舱兑换</strong>
				</p>
				<p>
					4.4.1. 会员兑换的奖励升舱必须是挂CZ航班号且由南航实际承运的南航航班，不含任何代码共享航班及专、包机航班。
				</p>
				<p>
					4.4.2. 付费机票、里程兑换的奖励机票均可进行奖励升舱兑换，适用的订座舱位详见《南航航班奖励升舱兑换标准表》；团队机票、旅行同业或其他特殊折扣机票和免费机票专机和包机航班机票不得兑换奖励升舱。
				</p>
				<p>
					4.4.3. 允许里程连续升舱，即里程升舱后的机票还可继续兑换升舱。
				</p>
				<p>
					4.4.4. 如客票使用规定注明“不得变更”，则不可办理奖励升舱兑换。
				</p>
				<p>
					4.4.5. 兑换奖励升舱时，原购机票附带的所有旅行限制和条件均适用，如奖励升舱只变更舱位，不变更客票航班和日期，免收变更费。
				</p>
				<p>
					4.4.6. 自愿更改航班或日期，优先按升舱后舱位变更，当无法保障按升舱后舱位变更时，会员可选择降舱变更，但已兑换里程不退还。
				</p>
				<p>
					4.4.7. 自愿签转和退票，按升舱前原客票舱位及使用规定办理，已兑换里程不退还。
				</p>
				<p>
					<strong>4.5 航空合作伙伴奖励机票兑换</strong>
				</p>
				<p>
					4.5.1. 兑换航空合作伙伴奖励机票需向南航客服热线95539申请。
				</p>
				<p>
					4.5.2. 兑换奖励机票的航班必须是航空合作伙伴独立运营的航班，航空合作伙伴之间或与非航空合作伙伴之间的代码共享航班不接受奖励机票兑换。
				</p>
				<p>
					4.5.3. 每家航空合作伙伴的奖励机票需单独兑换。
				</p>
				<p>
					4.5.4. 航空合作伙伴奖励机票兑换标准只限于单程奖励，来回程所需里程为单程的两倍。
				</p>
				<p>
					4.5.5. 兑换航空合作伙伴奖励机票，全航程必须确定航程、航班、日期，不接受不定期航班的兑换。选择航班时必须遵守航空合作伙伴关于奖励机票禁兑日期的规定。具体禁止兑换的规定详见会员网站。
				</p>
				<p>
					4.5.6. 更改条款：
				</p>
				<p>
					4.5.6.1. 需在机票有效期内进行更改，超出机票有效期，不允许更改，奖励机票不退。
				</p>
				<p>
					4.5.6.2. 已扣减里程的航空合作伙伴奖励机票在未出票前不得做任何变更；
				</p>
				<p>
					4.5.6.3.
					已出票的航空合作伙伴奖励机票不能改变航程和乘机人，在有效期内可更改航班、日期，国际航线和中国港澳台地区航线客票每航段每次更改收取更改费人民币200元或等值当地货币；中国大陆地区内客票每航段每次更改收取更改费
					人民币100元或等值当地货币（非自愿变更除外）。
				</p>
				<p>
					4.5.6.4. 需致电南航客服热线95539申请更改，南航客服热线95539可为旅客换开客票。或可选择到南航直属售票处办理。
				</p>
				<p>
					4.5.6.5. 南航航段按南航免票更改规则处理。
				</p>
				<p>
					4.5.6.6. 须核对经停、舱位、禁止兑换日期和其他所有限制条件。如不符合限制条件则不能更改。
				</p>
				<p>
					4.5.6.7. 出票前，因超过出票时限未出票导致座位被取消，且无法再成功订座时，更改作自愿退票处理。
				</p>
				<p>
					4.5.6.8. 更改航程/承运人/舱位（改变扣减里程）：视为退票，按自愿退票办理，再重新兑换新航程。
				</p>
				<p>
					4.5.6.9. 更改乘机人：按自愿退票处理，需重新兑换。
				</p>
				<p>
					4.5.7. 退票必须在航空合作伙伴奖励机票的有效期内办理，超出有效期，奖励机票作废，不退。
				</p>
				<p>
					4.5.8. 退票必须到南航指定的开办国际客票业务的南航直属售票处申请办理。
				</p>
				<p>
					4.5.9. 退票分为自愿退票和非自愿退票两种。由于退票航段涉及航空合作伙伴运营的航班，接到退票申请后，南航需要一定时间与承运航空公司确认退票属于自愿退票还是非自愿退票。
				</p>
				<p>
					4.5.10.
					自愿退票须扣除未使用航段的50%里程作为退票手续费，退还里程的有效期与兑换所使用里程的原始有效期一致，且仅可退还有效期内的里程；非自愿退票全数退还未使用航段的里程，退还的里程将在退还日期的基础上重新赋予36个月有效期。
				</p>
				<p>
					4.5.11.
					航空合作伙伴奖励机票自旅行之日起一年内有效，完全未使用的奖励机票自填开之日起一年内有效。客票换开后，新客票的有效期自旅行开始之日计算，一年内运输有效。不允许延期。延期作退票处理，退票后重新兑换。
				</p>
				<p>
					4.5.12. 航空合作伙伴的航班组成联运，需分别兑换和出票。川航国际航班暂不接受兑换。
				</p>
				<p>
					<strong>4.6 兑换受让人规则</strong>
				</p>
				<p>
					4.6.1 会员兑换奖励机票或奖励升舱，除本人使用，仅可转让给会员指定的已生效的兑换受让人使用。会员为他人兑换奖励前，须先建立兑换受让人名单。
				</p>
				<p>
					4.6.2 每名会员最多可设置8名兑换受让人。小明珠会员不允许添加受让人名单。
				</p>
				<p>
					4.6.3 首次建立受让人名单，受让人将在成功申请15天后生效；再次新增或变更受让人将于30天后生效。删除受让人即刻生效。
				</p>
				<p>
					4.6.4 铂金卡会员在一个日历年内有3人次机会使受让人变更实时生效。每新增一个受让人并立即生效算1人次。
				</p>
				<p>
					4.6.5 新增或删除受让人均不扣减里程。
				</p>
				<p>
					4.6.6 公司或法人实体均不可作为兑换受让人。
				</p>
				<p>
					4.6.7 会员账户开通身份认证后，会员可以通过会员网站或南航销售服务热线95539建立受让人名单，兑换受让人的姓名必须准确填写。
				</p>
				<p>
					4.6.8 受让人生效后，方可为受让人办理奖励机票和奖励升舱的兑换订座以及其他奖励品兑换。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>5. 会员等级和会员权益</strong>
				</p>
				<p>
					5.1.1 “南航明珠俱乐部”共有四个会员等级，从高到低分别是：南航铂金卡、南航金卡、南航银卡和南航明珠卡。2-11周岁注册会员为小明珠会员，具体权益规则详见5.3条款。
				</p>
				<p>
					5.1.2 每年的1月1日-12月31日作为评定会员新级别的资格年度。
				</p>
				<p>
					5.1.3
					“南航明珠俱乐部”将根据当个资格年度所累积的升级里程或升级航段来评定会员下一年新的会员资格。铂金卡的资格为16万公里升级里程或80个升级航段；金卡的资格为8万公里升级里程或40个升级航段；银卡的资格为4万公里升级里程或20个升级航段。
				</p>
				<p>
					5.1.4 升级里程和升级航段基于乘机年度进行累积，在下一年度提出并成功补登的上年度飞行记录将计入上年度（即乘机年度）的升级里程和升级航段。
				</p>
				<p>
					5.1.5 铂金、金、银卡到期前，将根据上述标准重新计算上一个资格年度的升级里程和升级航段，如果满足级别标准，将获取对应的精英会员级别。
				</p>
				<p>
					5.1.6 铂金、金、银卡会员的升级标准和保级标准相同。
				</p>
				<p>
					5.1.7 南航铂金、金、银卡的有效期为从金、银卡等级评定之当日起到第三年的2月28日（如资格评定当年视为第一年）。
				</p>
				<p>
					<strong>5.2 会员权益</strong>
				</p>
				<p>
					5.2.1 当搭乘南航或合作伙伴的有效航班及舱位时，铂金、金、银卡会员可获得额外的精英里程。
				</p>
				<p>
					5.2.1.1 精英里程仅计入"奖励里程"，不计入会员的"升级里程"。
				</p>
				<p>
					5.2.1.2 不可累积里程的舱位不可得到精英里程。
				</p>
				<p>
					5.2.2 南航精英等级会员权益仅在会员乘坐市场方和承运方均为南航的航班时提供。
				</p>
				<p>
					5.2.3 南航精英等级会员权益——享用贵宾休息室：
				</p>
				<p>
					5.2.3.1 南航铂金卡可携带两名同行人共同进入两舱休息室；南航金卡可携带一名同行人共同进入精英会员休息室候机；南航银卡会员仅限本人进入精英会员休息室。同行人指当天同是乘坐南航航班的旅客。
				</p>
				<p>
					5.2.3.2 对于由南航铂金、金卡会员邀请进入休息室的随行人员，其在申请休息室准入权时必须与南航铂金、金卡会员同时出现。
				</p>
				<p>
					5.2.3.3 南航仅在与南航签署休息室服务代理协议或由南航运营的休息室提供服务，所提供的服务等级视各地机场服务条件而定。
				</p>
				<p>
					5.2.4 南航精英等级会员权益——额外免费行李额：
					该额外免费行李在办理值机手续时根据会员精英级别提供，不在客票的免费行李栏体现。计件制航班额外免费行李的重量和尺寸要求，按会员所持客票座位等级对应的标准提供。
				</p>
				<p>
					5.2.5
					南航精英等级会员权益—航班不正常服务：在餐食、住宿、赔偿方面，购买头等舱、公务舱的铂金、金、银卡会员，适用对应舱位的航班不正常服务标准；购买经济舱的铂金卡会员，适用优于两舱旅客的航班不正常服务标准；购买经济舱的金、银卡会员，适用优于经济舱旅客的航班不正常服务标准。具体内容根据南航不正常航班旅客保障规则。”
				</p>
				<p>
					南航铂金卡、金卡、银卡会员乘坐合作航司自营或与南航代码共享航班旅行时，可体验到尊贵的精英会员服务。具体合作航司及权益请参阅官网。
				</p>
				<p>
					5.2.6
					南航精英等级会员权益—远机位贵宾车：铂金卡会员乘坐南航自营出港航班头等舱、公务舱，遇飞机不停靠廊桥时，在有条件的场站其本人乘坐贵宾车摆渡时可携带2名同一航班同行人，具体以当地实际可提供服务为准。
				</p>
				<p>
					<strong>5.3 小明珠权益及规则</strong>
				</p>
				<p>
					针对2-11岁儿童，“南航明珠俱乐部”推出儿童会员卡——“小明珠”会员卡。以下为“小明珠”会员具体亮点权益：
				</p>
				<p>
					5.3.1 累积
				</p>
				<p>
					5.3.1.1 乘机旅行时，小明珠会员的累积与成人明珠卡会员累积标准一致。
				</p>
				<p>
					5.3.1.2 合作伙伴消费中（比如入住酒店、刷卡消费等），小明珠会员具体入账里程以明珠俱乐部合作伙伴相关规定标准执行。
				</p>
				<p>
					5.3.2 兑换
				</p>
				<p>
					小明珠会员兑换标准与成人标准一致。
				</p>
				<p>
					5.3.3 其他
				</p>
				<p>
					5.3.3.1 里程有效期：2-11岁小明珠会员的里程不设有效期，年满12岁之日起，小明珠会员的里程将按明珠卡里程标准，全部自动转为36个月有效期计算。
				</p>
				<p>
					5.3.3.2
					优先值机：6周岁（含）以下小明珠会员可与陪同出行成人(最多两名)，出示会员卡及有效证件享国内部分明珠经济舱柜台优先值机权益（适用地区机场：广州、沈阳、北京、深圳、哈尔滨、长春、大连、武汉、海口、三亚、南宁、桂林、上海虹桥、上海浦东、西安、郑州、贵阳、揭阳、珠海、重庆、南阳、昆明、乌鲁木齐）。
				</p>
				<p>
					5.3.3.3 优先登机：6周岁（含）以下儿童卡会员可与陪同出行成人(最多两名)，出示儿童卡及有效证件享优先登机权益。
				</p>
				<p>
					5.3.3.4 机票受让人：小明珠可进行身份认证，但不允许添加受让人。
				</p>
				<p>
					5.3.3.5 监护人：在注册小明珠会员的时候需关联真实的监护人南航会员卡号及姓名，一名小明珠会员仅允许绑定一个监护人卡号。
				</p>
				<p>
					5.3.3.6 精英会员级别：小明珠会员不可获得南航精英会员级别，不允许累积升级里程及升级航段。
				</p>
				<p>
					5.3.3.7
					自愿或者非自愿退票：自愿或者非自愿退票里程有效期判断，具体参照会员年龄进行判断，2-11岁小明珠会员所有里程不设有效期，退还里程数规则参考明珠卡会员退还标准执行；12周岁（含）以上会员里程退还原则按照明珠卡退票规则执行。
				</p>
				<p>
					5.3.3.8 里程补登：小明珠补登里程可按照成人会员规则进行补登，婴儿票不允许补登。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>6.其他</strong>
				</p>
				<p>
					6.1 如因会员个人原因造成里程帐户密码丢失，导致里程被盗用等后果，“南航明珠俱乐部”将不负任何责任。
				</p>
				<p>
					6.2 所获奖励如因相关政府法规而应予课税/费（如燃油附加费、机场建设费等）由会员自行负担。
				</p>
				<p>
					6.3
					“南航明珠俱乐部”为每名会员都设置了初始个人密码。会员得到初始个人密码后，必须进行更改。作为使用帐户里程的重要识别标识，个人密码将为会员里程帐户的安全性及私密性提供更加可靠的保证，请会员妥善保存自己的密码并不定期的变更。会员须确保不向任何未经授权的人士透露此密码。明珠俱乐部承认每次通过密码发生的里程交易，如因会员向未经授权的人士透露其个人密码，或因任何人未经授权使用会员之个人密码而导致的任何后果，“南航明珠俱乐部”概不负责。
				</p>
				<p>
					6.4
					南航禁止其南航会员对其他南航会员实施任何损害行为。对于呈报任何虚假、错误资料或其它由南航认定为不当的行为，如违反南航或本计划的其它合作伙伴、航空公司的规定，任何对南航职员不当或骚扰行为，或拒绝接受职员之建议等，均会导致取消会员帐户及该会员继续参加本计划的资格，已累积的里程积分及先前已开立但尚未使用的奖励机票也将失效。任何违反本计划规则及条款的行为，将导致明珠俱乐部会员或乘机人在任何时间被没收机票（包括旅行中），并须缴付涉及滥用机票已搭乘航段的全额经济舱、公务舱及头等舱机票票款。对此明珠俱乐部规则与条款的执行，南航保留在必要时采取法律行动，以追讨赔偿、律师费和诉讼费的权利。
				</p>
				<p>
					6.5 通讯地址如有变更，请主动及时通知“南航明珠俱乐部”更正；因地址错误导致邮件遗失或延误，本俱乐部恕不负责。
				</p>
				<p>
					6.6 “南航明珠俱乐部”禁止一切里程买卖行为，否则后果自负。
				</p>
				<p>
					6.7 里程帐户的追溯有效期为六个月。如您对里程帐户的里程记录有任何疑问，请在记录发生之日起六个月内与我们联系；超过此期限，“南航明珠俱乐部”将不予受理。
				</p>
				<p>
					6.8 “南航明珠俱乐部”的规则中所涉及的生效或失效时间以北京当地时间为准计算。
				</p>
				<p>
					6.9 “南航明珠俱乐部”保留直接修改或终止其里程累积奖励计划的权利，且拥有会员卡的所有权。
				</p>
				<p>
					6.10 责任的免除和限制
				</p>
				<p>
					6.10.1 如遇到不可抗力（地质灾害等）、计算机黑客袭击、系统故障、通讯故障、网络拥堵、供电系统故障、电脑病毒、恶意程序攻击及其它不可归因于南航的情况时，南航不承担任何责任。
				</p>
				<p>
					6.10.2 如会员利用系统差错、故障或其它原因导致的漏洞，损害南航或其他会员的权益，“南航明珠俱乐部”将终止该会员资格，取消已获里程，并保留法律追究的权利。
				</p>
				<p>
					6.10.3 如果南航发现有影响会员账户及信息安全的行为，南航有权对会员账户实施保护。必要时需会员重新验证身份方可继续使用账户。
				</p>
				<p>
					6.11 个人资料隐私权及其它事项
				</p>
				<p>
					6.11.1、我们可能收集南航会员的下述信息，用于会员注册和会员身份认证：姓名，身份证号、护照号或其他有效身份证件号，手机或电子邮箱；监护人姓名、监护人卡号（仅针对“小明珠”）
				</p>
				<p>
					6.11.2、南航会员会因南航提供服务而产生如下个人信息，用于维护、管理会员账户、累积里程和航段、管理优惠信息：电子会员卡及密码、会员卡号、会员等级、会员里程及航段、会员消费账单、优惠券、会员订单、会员机票票号
				</p>
				<p>
					6.11.3、我们会在会员同意的前提下收集下述信息以开启特定的服务或功能：会员认证功能：收集会员的面部识别或者银行卡信息信息；南航钱包功能：收集会员的银行卡信息；支付功能：收集会员的银行卡等信息；南航商城：收集会员的收货地址、联系方式信息；女性孕产期关爱：收集会员的有效孕产医疗证明，包括但不限于诊断证明书、姓名、年龄、怀孕时间、孕产时间等信息；里程继承：收集会员的死亡证明、结婚证明、户口本等信息。我们会在您自愿同意使用我们的相关服务的前提下，将您的信息分享给与我们合作的第三方公司，以为您提供积分里程兑换、里程积累、享受南航提供的泊车、接送机、通信、上网、餐饮、商旅、休息室等多项会员服务。我们仅在必要范围内将您的数据分享给这些第三方，并且采取合理的安全措施保障您的数据安全。我们会将会员的个人数据存储至境内，但如您选择的服务中需要我们与境外合作伙伴合作提供，我们会按照与其他境外航空公司和合作伙伴的协议，将您的个人信息安全传输至境外的合作伙伴，以满足您在其他航空公司和合作伙伴中享受常旅客合作计划中的积分
					/ 里程累积、优惠兑换、休息室等服务。我们会依法与这些合作伙伴签订数据跨境传输协议，并且采取合理的安全措施保障您的数据安全。其他事宜，请参见我们的《隐私通知》。
				</p>
				<p>
					6.12 本规则与条款与当地政府法规相抵触时，以当地政府法规为准。
				</p>
				<p>
					6.13 如本规则及条款的中文版本与其它语言版本有差异，以中文版本为准，若有任何争议，南航保留最终解释权。
				</p>
				<p>
					6.14 本手册的部分规则和条款如由于版本或印刷问题未能及时更新，最新规则以会员网站（skypearl.csair.com）公布为准。
				</p>
				<p>
					6.15 如发生法律纠纷，提交广州仲裁委员会仲裁。
				</p>
			</div>
		
		</div>
		<div class="book-rule-content" v-if="tnType==2 ">
			<div class="pageHtml">
				<p>
					发布生效日期：2018年5月24日
				</p>
				<p>
					最新更新日期：2022年6月14日
				</p>
				<p>
					中国南方航空股份有限公司（以下简称“南航”，“南方航空”、“我们”）尊重并采取措施保护您的个人信息。
				</p>
				<p>
					本隐私政策旨在释明南方航空收集、使用、分享、存储和传输您的个人信息，以及您应如何行使您的个人信息保护的相关权利。本隐私政策适用于南航的任何机票业务以及南航为您提供的服务，包括但不限于南航授权第三方机构、代理人来协助您订票，以及您使用南航官方网站（<a
						href="http://www.csair.com">www.csair.com</a>）、南方航空App、南航微信公众号、小程序，或经南航授权的正规订票渠道，以及您使用南航的线上和线下服务时，南航所收集和使用的个人信息。
				</p>
				<p>
					本隐私政策的主要内容包括 ：
				</p>
				<p>
					一、 南航的业务是什么
				</p>
				<p>
					二、 南航收集您的个人信息的类型、方式和目的
				</p>
				<p>
					三、 南航如何委托处理、共享、转让、披露您的个人信息
				</p>
				<p>
					四、 南航如何使用Cookies及类似技术
				</p>
				<p>
					五、 南航处理个人信息的法律依据
				</p>
				<p>
					六、 南航如何保护您的个人信息
				</p>
				<p>
					七、 南航如何存储您的个人信息
				</p>
				<p>
					八、 不满14周岁未成年人的个人信息保护
				</p>
				<p>
					九、 南航在全球范围内转移您的个人信息
				</p>
				<p>
					十、 您的个人信息保护权利
				</p>
				<p>
					十一、 本隐私政策的更新
				</p>
				<p>
					十二、 如何举报、投诉或联系我们
				</p>
				<p>
					如果您对我们使用您的个人信息有任何疑问或顾虑，或希望向我们举报、投诉任何违反本隐私政策的行为，请通过本隐私政策底部所示联系方式与我们联系。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>一、 南航的业务是什么</strong>
				</p>
				<p>
					南航的总部设在广州，以蓝色垂直尾翼镶红色木棉花为公司标志，是中国运输飞机最多、航线网络最发达、年客运量最大的航空公司，拥有新疆、北方等多家分公司和厦门航空等多家控股航空子公司，在珠海设有南航通航，在杭州、青岛等地设有多个国内营业部，在新加坡、纽约等地设有多个国外办事处。
				</p>
				<p>
					目前，南航通过与合作伙伴密切合作，航线网络延伸到全球更多目的地。近年来，南航持续新开和加密航班网络，强化中转功能，利用第六航权，全力打造“广州之路”（Canton
					Route）国际航空枢纽，形成了以欧洲、大洋洲两个扇形为核心，以东南亚、南亚、东亚为腹地，全面辐射北美、中东、非洲的航线网络布局，已成为中国大陆至大洋洲、东南亚的第一门户枢纽。有关南航的更多信息，请参阅我们网站上的“<a
						href="http://www.csair.com/cn/about/gongsijianjie/index.shtml" target="_blank">公司简介</a>”的部分。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>二、 南航收集您的个人信息的类型、方式和目的</strong>
				</p>


				<p>
					南航基于不同的业务场景，可能会以下述方式收集以下个人信息：
				</p>
				<p>
					<u>1. 您向南航提供的信息</u>
				</p>
				<p>
					为履行我们的航空运输及相关服务协议，或履行我们的法定义务，或为保障公共利益和安全，防控突发卫生公共事件，保障您或其他人的生命健康和财产安全所需，或在欧盟或欧洲经济区境内为保障我们的合法正当利益，我们会请您提供如下个人信息：
				</p>
				<p>
					<strong>●
						身份信息以及联系方式：</strong>您的性别、出生日期、身份证号码、护照号码、国籍、常住国，您的姓名、地址、电话号码、电子邮件地址、传真号码，以及与您同行的其他人的个人信息（包括同行人的联系方式），用于将航班及订单消息（包括航班出行、安检、登机、报销、航班延误、保险业务以及事故通知）通知到您或相关联系人，为您安排行程、为您寄送行程单或其他产品、验证您的身份、邀请您反馈服务质量、接受您的投诉建议、为您提供“南航账户”服务、南航快乐飞服务以及南航的其他各类产品。请您注意，在您为其他人预订相关服务时，您需要提交该旅客的个人信息，向我们提供该旅客的个人信息之前，请您确保您已经取得本人的同意，并确保其已知晓并接受本隐私政策。
				</p>
				<p>
					<strong>●
						明珠会员信息：</strong>您的会员账号及航班信息、里程兑换的受让人信息、受托人信息、未成年会员的监护人信息，用于管理我们的明珠会员、验证会员身份，以及为您办理积分累计、奖励、兑换和其他常客计划中的服务。
				</p>
				<p>
					<strong>●
						业务服务所需的身份证件信息及图像：</strong>身份证、护照、签证页、符合民航局乘机要求的证件、有效期、签发机关、年龄或出生日期、性别，以及可能需要的对应证件图像，按照法规要求将用于您在办理机票预订、订座、值机、乘机、审核办理出入境手续、航空保险服务、提供“南航账户”服务以及其他为您提供的服务（如受理投诉）的过程中验证您的身份。
				</p>
				<p>
					<strong>●
						支付信息：</strong>您的信用卡号、账单地址、信用卡有效期、在线支付平台（支付宝、微信）提供给我们的的账户个人信息（如支付宝、微信支付）、“南航账户”信息、“南航账户”余额、订单和操作记录、日志、风控信息，以便于我们管理您购票时的支付信息、验证您的身份、提供“南航账户”服务、南航快乐飞服务。
				</p>
				<p>
					<strong>● 地理位置信息：</strong>?诸如您在填写订单时，为帮助您更快、更便捷的填写出行地址时我们会在征得您同意的情况获取您的位置信息。
				</p>
				<p>
					<strong>●
						有助于改进旅行服务或其他服务的信息：</strong>紧急联系人、特殊服务需求、个人喜好（机上餐食偏好、飞机座位偏好、机上服务偏好），用于提升我们产品和服务的相关性，改进我们的服务以及为您提供更贴合您要求的服务，以及在征得您同意的情况下可能会定向投放我们的营销广告。
				</p>
				<p>
					<strong>● 合同中所包含的个人信息：</strong>合同中的联系人姓名、职务、地址和联系方式（其中之一或多个信息），以便我们与企业的联系人沟通和寄送合同或相关文件材料。
				</p>
				<p>
					<strong>●
						其他个人信息：</strong>与其他航空公司的积分、里程活动相关的交易性事务及在线事务相关的个人信息；通过监控我们的产品及服务的使用情况，如自助设备、航班状态通知和网上办理登机手续获得的个人信息；通过问卷调查、意见征集或其他营销调查活动收集到的个人信息；提供给南航用于调查或解决问题的个人信息，例如验证您的身份的信息。
				</p>
				<p>
					我们使用这些个人信息来为您的旅行提供便利，为您提供其他旅行相关的商品和服务，管理我们的“南航明珠俱乐部”，开展市场营销，并向您提供关于我们的信息和联系方式。我们向您征询的个人信息及具体原因将在我们向您征询您的个人信息时向您明示。
				</p>
				<p>
					<u>2. 我们自动收集的信息</u>
				</p>
				<p>
					当您访问我们的网站、App、小程序、<strong>微信公众号</strong>，我们会因技术、设备方面的原因，自动地从您的设备中收集与您相关的特定信息。这些我们自动收集的信息将包含您的：
				</p>
				<p>
					<strong>●
						设备信息：</strong>设备型号、操作系统版本、浏览器版本号、IMEI号、Mac地址、IP地址、端口信息、DNS、移动运营商、数据网络制式、ROOT标识、网络接入方式、登录渠道、APP版本号、登录时间、硬件编号及其他与用户网络及系统、设备有关的日志信息、技术参数信息。
				</p>
				<p>
					<strong>● 设备的广泛地理位置</strong>（包括国家或者城市级别的位置）的信息，以及其他的技术信息。我们也会收集有关您的设备如何与我们交互的信息，包括您访问的网页和点击的链接。
				</p>
				<p>
					请您理解，这些信息是用于在国双、淘宝、MTA、极光推送、支付宝这些第三方分析判断设备唯一性及安全风控所必须收集的信息。我们将这些信息用于运营之目的，以帮助我们更好的理解我们的访客群体，以提升我们网站的水平和对访客的相关性。<strong>如您拒绝上述信息收集，我们将无法为您提供在线环境上（App、官方网站、小程序或微信公众号上）的基础功能或服务。</strong>
				</p>
				<p>
					这些信息中的一部分会使用<strong>Cookies</strong>和类似的追踪技术收集，如下所述“四、南航如何使用Cookies及类似技术”。
				</p>
				<p>
					<u>3. 我们从第三方处获得的信息</u>
				</p>
				<p>
					在您选用第三方和我们合作的产品或服务时，我们可能会从第三方来源收到有关您的个人信息，但这只应发生在：第三方履行与您签署相关的产品或服务协议所需，或为公共利益或防控公共卫生事件所需（如疫情防控所需），或者第三方依法被许可或被要求向我们披露您的个人信息的情况，或取得您的同意时。
				</p>
				<p>
					我们从第三方处收集的信息，涵盖了关联公司、业务合作伙伴，如专业旅行机构和其他协助订票、安排旅程的机构提供给的信息，以及代理人或机构通过南航团体网为您订票、安排行程而提供给我们的信息。我们将这些从第三方处收集的信息用于为您提供服务，以及用于保证我们所掌握的关于您的相关记录的准确性。
					此外，请您注意，专业旅行机构可能有其自身专门的隐私政策来说明其如何收集和处理您的个人信息，该等隐私政策不构成南航的隐私政策的一部分，请您自行审慎阅读后作出是否同意的决定。
				</p>
				<p>
					<u>4. 我们将获取的设备权限清单</u>
				</p>
				<p>
					以下是我们启动时会向您获取的设备权限及对应的说明，以便向您提供更优质的服务，届时会弹窗提示，建议您予以许可。在获取对应的权限前，我们会征询您的同意。您也可以通过设备操作系统的设置，关闭对应的授权。
				</p>
				<table class="dcampusTable ke-zeroborder" border="1" bordercolor="#CCC" cellpadding="8" cellspacing="0"
					width="100%;">
					<tbody>
						<tr>
							<td width="12%;">
								权限
							</td>
							<td>
								说明
							</td>
						</tr>
						<tr>
							<td>
								电话权限
							</td>
							<td style="text-align:left;">
								用于读取设备硬件信息，作为生成设备唯一标识的依据之一，通过技术与风控规则提高用户与设备的关联性。方便使用好友支付/行程接机/送机拨打司机电话功能。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								存储权限
							</td>
							<td style="text-align:left;">
								用于图片存储及上传、个人设置信息缓存读写、系统及日志文件创建、行程缓存/截图分享/保存图片到相册等功能。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								定位权限
							</td>
							<td style="text-align:left;">
								用于个性化信息推荐，如站点服务、城市服务和地图导航服务。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								通讯录
							</td>
							<td style="text-align:left;">
								需要访问通信录,以便访问联系人信息。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								网络服务
							</td>
							<td style="text-align:left;">
								“南方航空”APP首次启动时需要网络服务。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								短信发送
							</td>
							<td style="text-align:left;">
								需要访问短信，以便通过短信分享。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								相机、相册
							</td>
							<td style="text-align:left;">
								方便使用扫码、识别登机牌/人像/证件功能。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								麦克风
							</td>
							<td style="text-align:left;">
								需要访问麦克风,以便识别您的语音内容。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								手机状态
							</td>
							<td style="text-align:left;">
								需要读取手机状态，以便用于银联支付。
							</td>
						</tr>
						<tr>
							<td>
								蓝牙
							</td>
							<td style="text-align:left;">
								打开蓝牙来允许“南方航空”连接到配件。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								日历
							</td>
							<td style="text-align:left;">
								需要访问日历，以便管理您的航班动态和行程备忘功能。如您拒绝此项权限，仅会导致您无法使用对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								辅助功能
							</td>
							<td style="text-align:left;">
								用于使用无障碍辅助工具包括：旁白、缩放、放大器、显示与文字大小、动态效果、触控，如您拒绝此项权限，仅会导致您无法使用对应的辅助工具，但不影响您正常使用其他功能。
							</td>
						</tr>
						<tr>
							<td>
								个性化推荐
							</td>
							<td style="text-align:left;">
								用于商品、服务或信息的个性化推荐。结合依法收集的用户信息，通过算法模型预测您的偏好特征，匹配您可能感兴趣的商品、服务或信息，对向您展示的商品、服务或信息进行排序。如您拒绝此项权限，仅会导致您无法体验对应的功能服务，但不影响您正常使用其他功能。
							</td>
						</tr>
					</tbody>
				</table>
				<p>
					一般而言，我们仅会将收集到的您的个人信息用于本隐私通知中所描述的目的，或用于我们在收集您的个人信息时向您解释的目的。然而，如果当地适用的数据保护法允许，我们也可能将您的个人信息用于与我们告知您的目的所不同的其他目的（包括为了公共利益目的、科学或历史研究目的或者统计目的）。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>三、 南航如何委托处理、共享、披露您的个人信息</strong>
				</p>
				<p>
					我们可能会将您的个人信息披露给以下类别的主体：
				</p>
				<p>
					●
					我们的<strong>集团公司、关联方，以及我们委托第三方服务提供商</strong>（包括航空餐食提供商、地面服务提供商、信息系统提供商、接送机服务商、安保服务提供商）代表我们处理您的个人信息，<strong>为实现本隐私政策所描述的个人信息处理目的，或我们在收集您的个人信息时通知您的用途，会按照本隐私政策的要求处您的理个人信息。</strong>
				</p>
				<p>
					●
					<strong>与第三方合作伙伴共享或分享您的个人信息，</strong>包括但不限于：合作的在线旅游票务机构、机票分销方、代理机构、旅行社、酒店，与我们有代码共享、联运、联营合作的航空公司，与我们合作的保险公司，您选择使用的金融/支付机构，以及因风险防控需要而为我们提供必要、合理、正当的数据验证、身份核验等服务的第三方服务提供商。具体来说，合作的在线旅游票务机构、机票分销方、代理机构以您的名义安排折扣价格、购买机票以及为您提供其他退票、改签、积分和累积里程等服务；合作的旅行社、酒店在您的出行、住宿等方面提供更加便捷和高效的品质服务，并以您的名义为您的出现安排折扣价格和积分兑换；合作的航空公司在代码共享、航线联运和航线联营上为您提供更多航线选择和价格选择，向您提供例如机场贵宾休息室，协助运载或在您的旅程发生中断时帮助重新订票等服务；合作的保险公司获取您的必要信息为您投保并提供相应的服务；您选择使用的金融/支付机构将会帮助您通过我们进行电子钱包支付业务；第三方服务提供商根据我们的风险防控需要而获取必要、合理的个人信息来进行数据核验、身份验证服务，以协助我们提高网站用户信息的安全性。我们会在征求您的同意后，或按您的要求，仅为履行我们与您之间的合同所必需时，分享您的个人信息给第三方。
				</p>
				<p>
					●
					依据法律法规规定，或按政府主管部门的强制性要求，向<strong>主管行政执法机关、司法机关、其他监管机关，其他此类第三方</strong>（包括民航主管机关、海关、市场监督管理局、公安、国安、法院、检察院、网信部门或工信部门）<strong>分享或披露您的个人信息</strong>：（1）为符合法律法规的要求，例如关系国家安全、国防安全、公共安全、公共卫生、重大公共利益的情况时，或是关系到犯罪侦查、起诉、审判和判决执行的情况时；（2）实施、建立、维护我们的合法权利；（3）维护您或其他人的切身利益，如生命、财产安全等。
				</p>
				<p>
					● 与<strong>实际或潜在的交易方</strong>（及其代理和顾问）就任何实际或拟议的交易，合并或收购我们业务的任何部分进行沟通，但我们会通知交易方必须将您的个人信息仅用于本隐私通知所载目的；
				</p>
				<p>
					● 任何<strong>获得您的同意的其他人。</strong>此种情况包括您将您的个人信息授权给他人，由其代为预定、管理您的行程等。
				</p>
				<p>
					● <strong>我们的App使用了以下供应商的SDK，在您使用App的特定功能期间，我们可能会为了实现下述场景或服务目的，向下述SDK供应商共享或披露您的个人信息：</strong>
				</p>
				<table style="width:100%;" border="1" bordercolor="#CCC">
					<tbody>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td width="15%;">
								<strong>名称</strong>
							</td>
							<td width="15%;">
								<strong>使用业务场景</strong>
							</td>
							<td width="25%;">
								<strong>用途</strong>
							</td>
							<td width="20%;">
								<strong>个人信息字段</strong>
							</td>
							<td>
								<strong>第三方机构名称</strong>
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								建行龙支付<br>
								SDK
							</td>
							<td>
								订单支付
							</td>
							<td>
								支付
							</td>
							<td>
								设备信息
							</td>
							<td>
								中国建设银行
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								银联支付（云闪付）<br>
								SDK
							</td>
							<td>
								支付
							</td>
							<td>
								支付
							</td>
							<td>
								设备信息
							</td>
							<td>
								中国银联
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								支付宝支付<br>
								SDK
							</td>
							<td>
								订单支付
							</td>
							<td>
								支付
							</td>
							<td>
								设备信息<br>
								存储信息<br>
								网络信息
							</td>
							<td>
								支付宝
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								微信<br>
								SDK
							</td>
							<td>
								分享链接、图片到朋友圈<br>
								微信关联登录<br>
								购票支付
							</td>
							<td>
								微信登录、分享和支付
							</td>
							<td>
								应用信息<br>
								设备信息
							</td>
							<td>
								微信
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								博睿 <br>
								SDK
							</td>
							<td>
								风控
							</td>
							<td>
								用于系统风控。帮助我们及时发现APP使用过程中的问题，并快速定位和解决问题。提升数字化运维能力。
							</td>
							<td>
								设备信息<br>
								网络信息
							</td>
							<td>
								博睿
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								听云<br>
								SDK
							</td>
							<td>
								风控
							</td>
							<td>
								用于系统风控。帮助我们及时发现APP使用过程中的问题，并快速定位和解决问题。提升数字化运维能力。
							</td>
							<td>
								设备信息<br>
								网络信息
							</td>
							<td>
								听云
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								公安人脸<br>
								SDK
							</td>
							<td>
								人脸认证<br>
								会员二次验密
							</td>
							<td>
								人脸识别
							</td>
							<td>
								相机权限、存储权限、用户姓名、用户手机号、用户证件号
							</td>
							<td>
								广东公安机关
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								国双<br>
								SDK
							</td>
							<td>
								风控
							</td>
							<td>
								用于收集用户行为数据和系统运行稳定情况，以便我们分析系统及业务功能的使用情况，帮助我们提升系统体验
							</td>
							<td>
								设备信息<br>
								位置信息<br>
								应用信息
							</td>
							<td>
								北京国双科技有限公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								银联人脸<br>
								SDK
							</td>
							<td>
								在线实名认证<br>
								人脸识别
							</td>
							<td>
								人脸识别
							</td>
							<td>
								设备信息<br>
								应用信息<br>
								相机权限<br>
								存储权限
							</td>
							<td>
								中国金融认证有限公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								百度地图<br>
								SDK
							</td>
							<td>
								城市定位、航班动态飞行轨迹
							</td>
							<td>
								定位、导航
							</td>
							<td>
								设备信息<br>
								位置信息
							</td>
							<td>
								百度公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								百度语音<br>
								SDK
							</td>
							<td>
								知识搜索、航班查询
							</td>
							<td>
								语音识别
							</td>
							<td>
								设备信息<br>
								应用信息<br>
								音频权限
							</td>
							<td>
								
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								OCR<br>
								SDK
							</td>
							<td>
								新增/修改乘机人<br>
								会员身份认证<br>
								钱包新增银行卡<br>
								钱包开户
							</td>
							<td>
								扫描识别证件和银行卡
							</td>
							<td>
								相机权限<br>
								存储权限
							</td>
							<td>
								译图智讯
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								微博<br>
								SDK
							</td>
							<td>
								微博分享
							</td>
							<td>
								微博分享
							</td>
							<td>
								设备信息<br>
								应用信息
							</td>
							<td>
								新浪微博
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								QQ互联<br>
								SDK
							</td>
							<td>
								QQ登录
							</td>
							<td>
								QQ登录
							</td>
							<td>
								设备信息<br>
								应用信息
							</td>
							<td>
								腾讯
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								电子行李牌<br>
								SDK
							</td>
							<td>
								电子行李牌
							</td>
							<td>
								电子行李牌
							</td>
							<td>
								无
							</td>
							<td>
								BAGTAG
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								极光<br>
								SDK
							</td>
							<td>
								消息推送
							</td>
							<td>
								推送
							</td>
							<td>
								设备信息<br>
								网络信息<br>
								应用信息
							</td>
							<td>
								jpush
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								爱加密<br>
								SDK
							</td>
							<td>
								风控
							</td>
							<td>
								用于对数据进行加密，保障用户数据安全、系统数据安全和通信安全。
							</td>
							<td>
								无
							</td>
							<td>
								北京智游网安科技有限公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								阿里 mPaaS<br>
								SDK
							</td>
							<td>
								南航APP
							</td>
							<td>
								用于离线包，提高用户浏览APP内H5网页的体验
							</td>
							<td>
								设备信息<br>
								存储信息
							</td>
							<td>
								阿里云
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								阿里waf<br>
								SDK
							</td>
							<td>
								风控
							</td>
							<td>
								用于系统风控。监控和阻拦爬虫，保障系统安全与稳定，防止爬虫爬取数据与损坏系统。
							</td>
							<td>
								设备信息
							</td>
							<td>
								阿里云
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								苹果支付<br>
								（Apple Pay）
							</td>
							<td>
								支付
							</td>
							<td>
								支付
							</td>
							<td>
								设备信息
							</td>
							<td>
								苹果
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								华为<br>
								SDK
							</td>
							<td>
								华为第三方登录、华为电子会员卡领取
							</td>
							<td>
								登录<br>
								分享<br>
								电子会员卡
							</td>
							<td>
								设备信息<br>
								应用信息
							</td>
							<td>
								华为
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								支付宝登录<br>
								SDK
							</td>
							<td>
								登录
							</td>
							<td>
								登录
							</td>
							<td>
								设备信息<br>
								网络信息
							</td>
							<td>
								支付宝（中国）
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								银联卡在线实名认证
							</td>
							<td>
								在线实名认证
							</td>
							<td>
								在线实名认证
							</td>
							<td>
								设备信息<br>
								应用信息<br>
								相机权限<br>
								存储权限
							</td>
							<td>
								中国金融认证有限公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								人脸识别认证
							</td>
							<td>
								在线实名认证
							</td>
							<td>
								在线实名认证
							</td>
							<td>
								设备信息<br>
								应用信息<br>
								相机权限<br>
								存储权限
							</td>
							<td>
								中国金融认证有限公司
							</td>
						</tr>
						<tr style="text-align:center;word-wrap:break-word;word-break:break-all;">
							<td>
								一键登录
							</td>
							<td>
								登录
							</td>
							<td>
								登录
							</td>
							<td>
								设备信息<br>
								应用信息<br>
								手机SIM卡信息<br>
								存储权限
							</td>
							<td>
								中移互联网有限公司
							</td>
						</tr>
					</tbody>
				</table>
				<p>
					除维护南航APP运行的必备基础功能外，您将有权在使用南航APP时，自愿选择是否使用上述的功能。<strong>您在使用南航APP期间我们会持续使用您的授权，在您停止使用APP后，我们会停止使用。</strong>
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>四、 南航如何使用Cookies及类似技术</strong>
				</p>
				<p>
					我们使用cookies和类似的追踪技术（统称为“Cookies”）来收集和使用您的个人信息。关于我们使用的Cookies类型、为什么使用Cookies以及如何控制Cookies的更多信息，请参阅我们的<a
						href="https://www.csair.com/newh5/cn/tourguide/booking/orders/static/cookiepolicy/index.shtml"
						target="_blank">Cookies声明</a>。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>五、 南航处理个人信息的法律依据</strong>
				</p>
				<p>
					我们上述收集或使用个人信息的法律依据，将取决于相关的个人信息以及我们收集个人信息时的具体情况。我们通常只会在下列情况下收集和使用您的个人信息：
				</p>
				<p>
					（1）为履行我们与您之间合法有效的合同（如航空运输总条件及其他特殊服务或附加服务协议、南航明珠会员俱乐部会员手册）所需;
				</p>
				<p>
					（2）为遵守法律法规的要求所需（如遵守中国民航的相关规定，航空公司需收集旅客真实姓名和身份证号，遵守网络安全等级保护义务需存储日志信息）。
				</p>
				<p>
					（3）为应对突发公共卫生事件，或保障公共利益，您及其他人的生命健康和财产安全所需（如：疫情防控需要，我们会请您出示您的健康证明或按照有权机关要求邀请您填写相关信息）
				</p>
				<p>
					（4）在欧盟或欧洲经济区境内，我们可能会因我们自身的合法利益（或任何第三方的合法利益）而收集或使用您的个人信息，此利益通常是为了：运营我们的公司以及我们在必要时与您沟通以向您提供我们的服务，以及为实现我们合法的商业利益，包括回复您的问题、改进我们的产品或服务、市场营销或检查、防范违法行为之目的。此外我们可能还有其他的合法利益，并会在合适的情况下于相关时间对您进行说明。
				</p>
				<p>
					（5）征得您的同意时（如您可自愿填写信息来接受我们的最新营销活动或优惠折扣）；
				</p>
				<p>
					如果我们因履行我们与您之间的有效合同、遵守我们的法定义务、应对突发公共卫生事件或为保障您的生命健康和财产安全为目的，或在欧盟或欧洲经济区内适用企业合法正当利益时，要求您提供个人信息，我们将在收集信息前或收集信息时明确说明，并告知您是否必须提供个人信息，以及如果您拒绝提供个人信息可能导致的后果。
				</p>
				<p>
					如果您对我们收集和使用个人信息的法律依据有任何疑义或需要进一步的信息，请按“如何联系我们以及投诉、举报渠道”标题下的详细的联系方式与我们联系。我们上述收集或使用个人信息的法律依据将取决于相关的个人信息以及我们收集个人信息时的具体情形。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>六、 南航如何保护您的个人信息</strong>
				</p>
				<p>
					我们使用适合的技术手段和组织措施来保障我们所收集的您的个人信息。我们采取的措施旨在为您提供与处理您的个人信息产生的风险相匹配的安全保障级别。我们采取的具体措施包括安全套接层（SSL）加密技术，以确保您的数据自您的浏览器至我们的网络系统的过程中能够实现安全传输。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>七、 南航如何存储您的个人信息</strong>
				</p>
				<p>
					为了向您提供您所需的航空运输服务及相关服务（订、退、改签机票）、会员服务、南航的各类产品，履行与您的各项协议，遵守适用的法律、税务、财务会计要求以及南航的合法的商业需求，我们会加密或者脱敏存储从您处收集的个人信息。
				</p>
				<p>
					如果我们不因上述理由来存储您的个人信息，我们将删除您的个人信息或将其匿名化。如果因客观原因，不能立即删除或匿名化，则我们将安全地存储您的个人信息，并在能够删除或匿名化此类信息前避免对其做进一步的处理。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>八、不满14周岁未成年人的个人信息保护</strong>
				</p>
				<p>
					南航非常重视对未成年人个人信息的保护。若您是不满14周岁的未成年人，或您根据其他国家的法律被认定为是儿童，在使用我们的产品与/或服务前，应事先取得您家长或法定监护人的书面同意。南航根据国家相关法律法规的规定保护儿童及其他年龄的未成年人的个人信息。
				</p>
				<p>
					对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或披露此信息。
				</p>
				<p>
					如有未成年人在未得到其父母或监护人同意的情况下向我们提供了个人信息，其父母或监护人可按“如何举报、投诉或联系我们”标题下的详细的联系方式与我们联系，以删除此类信息或退定市场营销推广方面的邮件或其他活动。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>九、 南航在全球范围内转移您的个人信息</strong>
				</p>
				<p>
					您的个人信息可能会被传输至您经常居住地所在国之外的其他国家进行处理。这些国家可能适用不同的个人信息保护相关的法律（请您须知，在不同的国家的法律下，您的个人信息的受保护程度可能是不同的）。
				</p>
				<p>
					具体而言，我们的网站服务器位于中华人民共和国境内，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。我们的集团办公室以及我们的第三方服务商和合作伙伴遍布世界各地。这意味着当我们收集您的个人信息时，我们会在上述的这些国家内处理这些数据。同时，您的个人信息可能被传输至您的经常居所地以外的国家或地区进行处理。此时，我们会根据适用的法律规定来保护您的个人信息安全，并在全球范围内传输您的个人信息，包括但不限于：
				</p>
				<p>
					若您的个人信息被从中华人民共和国境内传输至中华人民共和国境外，我们将按照法律及监管的要求，依法开展风险评估和/或个人信息保护影响评估，或签署中国网信部门颁布的标准合同条款。基于您的同意而传输个人信息出境时，我们会依法向您告知相关信息，并取得您的单独同意。
				</p>
				<p>
					若您的个人信息被从欧盟或者欧洲经济区传输至欧盟委员会未作出充分决定的国家时，我们将签署欧盟委员会批准的标准合同条款并采取安全措施保障您的个人信息安全。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>十、 您的个人信息保护权利</strong>
				</p>
				<p>
					您享有以下个人信息保护的权利：
				</p>
				<p>
					●
					如果您有意<strong>查询、复制、转移更正、更新或删除</strong>您的个人信息，您可以随时通过“如何举报、投诉或联系我们”标题下所提供的详细联系方式来联系我们。<strong>但请您注意，在行使删除的权利时，并非您的所有的个人信息都能够删除，我们仅会在符合法律规定的删除条件满足时进行删除，例如在您注销时删除您的个人信息，否则我们将无法维持和管理您的会员资格。</strong>
				</p>
				<p>
					●
					您可选择<strong>注销</strong>您在南方航空的明珠会员账户，您可以通过打开APP后在首页依次点击”我-设置-账户中心-注销南航会员“后根据指引完成会员注销，也可致电客户服务热线95539对南航会员进行注销。<strong>注销账户后，我们将停止提供相应的产品或服务，并对您的个人信息做删除或匿名化处理，请您须知，注销后，您的个人账户内未使用的积分和里程将一并被清理归零。</strong>
				</p>
				<p>
					●
					此外，您有权<strong>拒绝我们处理</strong>您的个人信息，有权要求我们<strong>限制处理</strong>您的个人信息或<strong>请求携带</strong>您的个人数据。并且您可以通过“如何举报、投诉或联系我们”标题下所提供的详细联系方式来联系我们，以行使这些权利。
				</p>
				<p>
					●
					您随时有权选择<strong>退订</strong>我们发予您的市场营销信息。您可以通过点击我们发予您的营销电子邮件中的“取消订阅”或“退订”的链接来行使此权利。如果您欲选择退订其他营销信息，请使用“如何举报、投诉或联系我们标题”下的详细联系方式来联系我们。
				</p>
				<p>
					●
					同样，如果我们在征得您的同意前提下收集并处理了您的个人信息，您有权随时<strong>撤回您的同意</strong>。您可以通过您手机的设置功能来改变您授权同意的范围或撤回您的授权，不同手机厂商或型号和APP版本方式不同，届时可以联系我们为您处理。请您理解，当您撤回或拒绝时，我们将无法为您提供所对应的服务，也不再处理您相应的个人信息。同时您撤回同意不会影响我们此前的任何处理行为的合法性，也不会影响依据法定理由而非基于您的同意而处理您的个人信息的合法性。
				</p>
				<p>
					●
					您有权向数据保护执法机构<strong>投诉</strong>我们收集和使用您个人信息的行为。有关更多信息，请联系您当地的个人信息保护监管机构。欧洲经济区、瑞士的数据保护执法机构的详细联系方式见<a
						href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
						target="_blank">此处</a>。
				</p>
				<p>
					我们会根据适用的个人信息保护的相关法律规定来回应相关个人的个人信息保护的权利请求。<strong>对于App上行使以上访问、更正、删除、注销等功能，我们会按照相关规定，在15个工作日内完成上述请求。</strong>
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>十一、 本隐私政策的更新</strong>
				</p>
				<p>
					我们可能不时地更新本隐私通知以适应法律、技术或商业的发展。若适用的数据保护法律要求我们在实质修订隐私通知时征得您的同意，我们将遵守法律的规定，以官网上公告、App内推送、电子邮件、寄信、电话的其中之一或多个方式来告知您。如您对本隐私政策的更新有任何异议，请您按下方的联系方式联系我们。
				</p>
				<p>
					<br>
				</p>
				<p>
					<strong>十二、 如何举报、投诉或联系我们</strong>
				</p>
				<p>
					如果您对我们使用您的个人信息有任何疑问或疑虑，请使用以下详细信息联系我们：
				</p>
				<p>
					南方航空的个人信息保护负责人联系信息：
				</p>
				<p>
					国内：+86-4006695539
				</p>
				<p>
					国际：+86-4008695539
				</p>
				<p>
					电子邮箱地址：dpo@csair.com
				</p>
				<p>
					公司注册地址：广东省广州市黄埔区玉岩路12号冠昊科技园区一期办公楼三楼301室
				</p>
				<p>
					公司其他联系地址：广东省广州市白云机场南方航空客户服务柜台
				</p>
				<p>
					<br>
				</p>
				<p>
					如您有隐私和个人信息保护之外的其他问题，请联系以下电子邮箱地址：95539@csair.com
				</p>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'TermsAndNotices',
		props: {
			tnType: {
				type: Number,
				default: 1,
			}
		},	
	}
</script>

<style scoped lang="scss">
	.book-rule-content {
		text-align: left;
	}

	.font16 {
		font-size: 16px;
	}

	.black {
		color: #333;
	}

	.marginTop30 {
		margin-top: 30px;
	}

	.font12 {
		font-size: 12px;
	}

	.line-height24 {
		line-height: 24px;
	}

	.pageHtml {
		p {
			font-size: 12px;
			color: #333;
			line-height: 24px;
		}

		table {
			width: 100%;
			background-color: #fff;
			margin: 10px 0;
			text-align: center;
			border-collapse: collapse;
			font-size: 10px;
			box-sizing: border-box;
		}
		strong {
			font-size: 14px;
		}
	}

	.oldHtmlpage {
		p {
			font-size: 12px;
			color: #333;
			line-height: 24px;
		}

		strong {
			font-size: 14px;
		}
	}
</style>
