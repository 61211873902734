<template>
	<div class="wanFan-tab">
		<div class="segment_tab_list">
			<div class="segment_tab " 
				:class="{'active':tkQuery.isQueryTravelType==1}" 
				@click="changeTab(1)">
				<span class="seq_mark">1</span>
				<span class="title">选择去程</span>
				<span class="sub_title">{{tkQuery.dep}}<span class="iconfont yj-icon-tp_icon_dcbz1"></span>{{tkQuery.arr}}&nbsp;{{tkQuery.depDate | filterMDformat}}&nbsp;{{tkQuery.depDate | filterWeekformat}}</span>
			</div>
			<div class="segment_tab " 
				:class="{'active':tkQuery.isQueryTravelType==2,'disabled':!isChooseTravelOne}" 
				@click="changeTab(2)">
				<span class="seq_mark">2</span>
				<span class="title">选择返程</span>
				<span class="sub_title">{{tkQuery.arr}}<span class="iconfont yj-icon-tp_icon_dcbz1"></span>{{tkQuery.dep}}&nbsp;{{tkQuery.arrDate | filterMDformat}}&nbsp;{{tkQuery.arrDate | filterWeekformat}}</span>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		props:{
			tkQuery:{
				type:[Object],
				default (){
					return {}
				}
			},
			isChooseTravelOne:{
				type:[Boolean],
				default (){
					return false
				}
				
			}
		},
		methods:{
			changeTab(tab){
				if(!this.isChooseTravelOne){
					return false
				}
				this.$emit('changeIsQueryTravelType',tab)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.wanFan-tab{
		position: relative;
		text-align: left;
		margin-top: 15px;
	}
	
	.segment_tab_list{
		display: inline-block;
		background-color: #dde4ed;
		color: #5678a8;
		border-radius: 8px 8px 0 0;	
	}
	.segment_tab{
			display: inline-block;
			min-width: 336px;
		    height: 44px;
		    display: inline-block;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
		    vertical-align: middle;
		    padding: 10px 28px 0 24px;
		    border-radius: 8px 8px 0 0;
		    position: relative;
		    cursor: pointer;
			
			
			.title{
				display: inline-block;
				font-size: 18px;
				padding-left: 8px
			}
			.sub_title{
				display: inline-block;
				font-size: 14px;
				padding-left: 5px;
			}
			.seq_mark{
				    display: inline-block;
				    font-weight: 700;
				    width: 24px;
				    height: 24px;
				    border-radius: 14px ;
				    text-align: center;
				    color: #fff;
				    line-height: 22px;
				    font-size: 19px;
			}
			.seq_mark {
			    background-color: #aabbd3;
			}
	}
	.segment_tab.disabled{
		cursor: not-allowed
	}
	.segment_tab.active{
		background-color: white;
		.title {
		    color: #0086f6;
		}
		.sub_title{
			color: #333;
		}
		.seq_mark{
			background-color:#0086f6;
		}
		
	}
</style>
