<template>
	<div class="qu-flight-item">
		<span>已选去程：</span>
		<span>{{chooseFlight.departAirportName}}</span>
		<span class="iconfont yj-icon-tp_icon_dcbz1"></span>
		<span>{{chooseFlight.arriveAirportName}}</span>
		<span>{{chooseFlight.departDate | filterMDformat}}</span>
		<span>{{chooseFlight.departDate | filterWeekformat}}</span>
		<span>{{chooseFlight.departTime}}-{{chooseFlight.arriveTime}}</span>

		<span class="color999">{{chooseFlight.flyTimeStr | flightFlyTime}}</span>
		<span class="color999">{{chooseFlight.stopNum | filterStopNum}}</span>
		<span class="fen-ge"></span>
		<!-- 航班详情 -->
		<img class="airline_logo" :src="chooseFlight.airlineImg" >
		<span>{{chooseFlight.airlineName}}</span>
		<el-link :underline="false"  type="primary">{{chooseFlight.flightNo}}</el-link>
		
		
		<el-button class="choose-btn" type="primary" plain @click="changeChooseFlight()">修改去程</el-button>
	</div>
</template>
<script >
	 export default{
		data(){
		   return {
			   
		   }
		},
		props:{
			chooseFlight:{
				type:Object,
				default(){
					return {}
				}
			}
		},
		computed:{
			chooseCabin(){
				return chooseFlight.chooseCabin ||{}
			}
		},
		filters:{
			filterStopNum(val){
				val = val || 0;
				var obj={
					0:"直飞",
					1:'经停'
				}
				return obj[val]
			},
			flightFlyTime(val){
				var str = '';
				if(val){
				 str = val.replace(/小时/,'h').replace(/分钟/,'m')
				}
				return str
			}
		},
		methods:{
			// 修改去程航班
			changeChooseFlight(){
				this.$emit('changeChooseFlight',1);
			}
		}
		
	 }
</script>

<style scoped>
	.qu-flight-item{
			font-size: 14px;
		    color: #333;
		    -webkit-box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
		    background: #fff;
		    padding: 25px 24px;
		    position: relative;
			text-align: left;
			margin-bottom: 1px;
	}
	.qu-flight-item span{
		margin-right: 4px;
	}
	.airline_logo{
		width: 16px;
		    height: 16px;
		    margin: 0 3px 0 8px;
		    vertical-align: middle;
	}
	.choose-btn{
		float: right;
		margin-top:-5px ;
	}
	.color999{
		color: #999;
	}
	.fen-ge{
		display: inline-block;
		border-left: 1px solid #e4e4e4;
		height: 14px;
		display: inline-block;
		vertical-align: middle;
	}
	
	/deep/ .el-link{
		vertical-align:top;
	}
	
</style>