<template>
	
		<div class="result-header">
			<div class="flight-part">
				<div class="lead">单程：</div>
				<div class="address">
					<span class="depart">{{tkQuery.dep}}{{tkQuery.depszm}}</span>
					<i class="iconfont yj-icon-tp_icon_dcbz1"></i>
					<span class="arrive">{{tkQuery.arr}}{{tkQuery.arrszm}}</span>
					<div class="sub-msg">
						<div class="date">{{tkQuery.depDate | filterMDformat}}<span class="week">{{tkQuery.depDate | filterWeekformat}}</span></div>
					</div>
				</div>
			</div>
			<!-- tips -->
			<div class="tips">
				显示价格不包含民航基建及燃油税，所有航班的起飞/到达时间均为当地时间
			</div>
		</div>
	
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		methods:{
			
		},
		props:{
			tkQuery:{
				type:[Object],
				default (){
					return {}
				}
			}
		},
		mounted(){
			// console.log(this.tkQuery)
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.result-header{
		text-align: left;
		padding: 5px 0;
	}
	
	.flight-part .lead,
	.flight-part .address,
	.flight-part .sub-msg {
	    display: inline-block;
	    font-size: 18px;
	    color: #333;
	}
	.flight-part .address .abbr,
	.flight-part .address .arrive,
	.flight-part .address .iconfont, 
	.flight-part .address .depart {
	    display: inline-block;
	    line-height: 14px;
	    vertical-align: baseline;
		font-size: 24px;
		font-weight: 700;
	}
	.flight-part{
		    position: relative;
		    height: 50px;
		    line-height: 1;
		    padding-right: 260px;
		    
			
			.lead{
				  
					position: relative;
					padding: 0 5px 0 10px;
					line-height: 50px;
					margin-right: 0;
					
			}
			.address{
				color: #000000;
				.depart,.arrive{
					font-size: 24px;
					font-weight: 700;
					display: inline-block;
					line-height: 14px;
					vertical-align: baseline;
				}
			}
			.sub-msg{
				
				padding-left: 10px;
				.week{
					padding-left: 5px;
				}
			}
	}
	
	.tips{
		padding: 0 5px 10px 10px;
		// height: 11px;
		font-size: 11px;
		font-family: '微软雅黑';
		font-weight: 500;
		color: #99A5BB;
		line-height: 1;
	}
	
	
</style>
