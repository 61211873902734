<template>
	<div class="card-no-debt">
		<div class="flight-no-debt">
			<h3 class="head-h3 ">航班信息免责声明</h3>
			<div class="disclaimer ">
				
				<p class="paddingLR10">
					<span>壹嘉商旅</span>网提供的各类航班相关的信息，包括但不限于舱位价格、餐食、起降准点率等，来源于中国航信、全国各大机场、航空公司及相关部门公布数据。由于这些信息可能随时变化，以及网络传输问题，壹嘉商旅提供的此类信息可能并非最新或存在误差，因此<b>仅供旅客作为一般参考</b>，任何公司或个人不能将此作为壹嘉商旅做出任何承诺或做出任何保证的依据。
				</p>
			</div>
			<div class="data_travelsky ">
				<span :style="calcStyle(zghxLogo)"></span>
				数据信息由<br>
				中国民航信息网络股份有限公司提供
			</div>
		</div>
	</div>
	
</template>

<script>
	import zghxLogo from '@/assets/image/home/zghx.png'
	export default {
		name:"FlightNoDebt",
		data() {
			return {
				zghxLogo:zghxLogo
			}
		},
		methods:{
			calcStyle(logoImg){
				return {
					'background-image':`url(${logoImg})`
				}
			}
		}
	}
</script>

<style scoped>
	.card-no-debt{
		padding-top: 30px;
		background-color: transparent;
	}
	.flight-no-debt{
		text-align: left;
		padding: 20px;
		background-color: white;
		color: #000;
		font-size: 13px;
	}
	.disclaimer {
		margin-top: 10px;
	    color: #999;
		font-size: 12px;
	    
	}
	.data_travelsky{
		text-align: right;
		margin-top: 10px;
	}
	.data_travelsky span {
	    background-repeat: no-repeat;
		display: inline-block;
		width: 60px;
		height: 40px;
		float: right;
	}
</style>
